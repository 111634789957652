/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {BsRewind} from 'react-icons/bs'

export default function ReWindModal({ open, setOpen }) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 max-w-1/2 w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-900">
                                        <BsRewind className="h-6 w-6 text-blue-500" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Rewind Therapy for PTSD
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                I am an approved Rewind Trauma Therapy practitioner and received training from Dr Muss, founder of The International Association for Rewind Trauma Therapy (IARTT)  <a href='http://www.iartt.com/the-rewind/' target={'_blank'} className='text-blue-400'>http://www.iartt.com/the-rewind/</a>.
                                            </p>

                                            <p className="text-sm text-gray-500 mt-2">
                                                Rewind is described as the therapy which offers ‘closure without exposure’.  The technique helps the brain to process traumatic incidents differently without any need to talk about the trauma.
                                            </p>

                                            <p className="text-sm text-gray-500 mt-2">
                                                Dr Muss introduced Rewind into the literature of Post-Traumatic Stress Disorder several decades ago, it has since become increasingly recognised as a successful treatment for PTSD with success rates at hight as 85% reported.
                                            </p>

                                            <p className="text-sm text-gray-500 mt-2">
                                                The benefits of non-disclosure include:
                                                <div className=''>
                                                    <li className=''>
                                                        Minimizing the risk of the client being re-traumatized.
                                                    </li>
                                                    <li>
                                                        There is no fear of disclosing sensitive information
                                                    </li>
                                                </div>
                                            </p>
                                            <p className="text-sm text-gray-500 mt-2">
                                                The Rewind helps prevent trauma survivors involuntary recalling traumatic events.  The ability to recall the event on demand remains.
                                                <br />In Dr Muss’ words,

                                                <span className='font-bold'>
                                                    ‘It puts your traumas into your memory once and for all, you will be free of them but you can recall them when you want’.
                                                </span>

                                                font     </p>

                                            <p className="text-sm text-gray-500 mt-2">
                                                For multiple event traumas, each traumatic event can be processed separately over separate sessions.
                                            </p>

                                            <p className="text-sm text-gray-500 mt-4">
                                                Successful outcomes have been reported on a wide range of traumatic events such as:

                                                <div className='grid grid-cols-3 mt-2 gap-4'>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Sexual Abuse
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Victims of violence
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Past bullying
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Car accidents
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Pathological & traumatic grief
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Attacks or robbery
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Suicide attempts
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Traumatic birth
                                                    </div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Physical and mental abuse
                                                    </div>
                                                    <div></div>
                                                    <div className='col-span-3 md:col-span-1 bg-blue-500 rounded-sm p-2 text-white'>
                                                        Phobias
                                                    </div>
                                                    <div></div>
                                                </div>

                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}