function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {

    return (
        <section id={"pricing"} className={'snap-start shrink-0'}>
        <div className="py-24 sm:py-32 h-full flex m-auto">
            <div className="m-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="mt-2 text-4xl font-bold tracking-tight text-blue-500 sm:text-5xl">
                        Pricing
                    </p>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 mx-auto">
                    <div
                        key={1}
                        className={classNames(
                            'ring-2 ring-blue-600',
                            'rounded-3xl p-8'
                        )}
                    >
                        <h3
                            className={classNames(
                                'text-blue-600',
                                'text-lg font-semibold leading-8'
                            )}
                        >
                            1h Session
                        </h3>
                        <p className="mt-4 text-sm leading-6 text-gray-600">{"I provide a free 15 minute telephone consultation. If you decide you would like to proceed, the necessary documentation will be sent, along with my contract. We can work face to face in Darlington, Online via Zoom,  or by  telephone,"}</p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">{"£50-60"}</span>
                            <span className="text-sm font-semibold leading-6 text-gray-600">{'per session'}</span>
                        </p>
                        <a
                            href="#contact"
                            className={classNames(
                                'bg-blue-600 text-white shadow-sm hover:bg-blue-500',
                                'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            )}
                        >
                            Enquire
                        </a>

                        {/*<ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">*/}
                        {/*    {tier.features.map((feature) => (*/}
                        {/*        <li key={feature} className="flex gap-x-3">*/}
                        {/*            /!*<CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />*!/*/}
                        {/*            {feature}*/}
                        {/*        </li>*/}
                        {/*    ))}*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}
