export default function About() {
    return (
        <section className="snap-start shrink-0 flex items-center justify-center overflow-hidden px-6 lg:px-8 h-full "
                 id="about">
            <div
                className="flex flex-col justify-center items-center relative mx-auto max-w-2xl py-24 sm:py-32 lg:max-w-4xl">
                <div
                    className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36"/>
                <div
                    className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center"/>
                <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
                    <div className="relative col-span-2 lg:col-start-1 lg:row-start-2 text-justify">
                        <blockquote className="text-md font-semibold text-gray-900">
                            <p>
                                I have experience of working with both adults and young people who experience
                                difficulties ranging from anxiety, panic,
                                worry, low-mood, grief, sexual abuse
                                and difficulties with relationships to processing traumatic experience. I work deeply
                                and collaboratively with my clients to help
                                them understand how their past experiences impacts on them in the here and now and
                                explore what they can do differently to help
                                them move forward to a better place.
                                I believe that we are all unique and a product of our own subjective experience.
                                I work with you to find out what you would like to achieve from your therapy and tailor
                                the sessions accordingly.
                                I take an integrative approach which means I incorporate elements of different therapies
                                such as CBT (Cognitive Behavioural Therapy),
                                Transactional Analysis, Psychodynamic and Rewind Therapy <a
                                className={"text-blue-500 text-decoration-none"}
                                href={"http://www.iartt.com/the-rewind/"} target={"_blank"}>(Visit Here)</a> all of
                                which are built on a solid Person-Centred
                                base which focuses on the client-therapist relationship, the most important factor of
                                any therapy undertaken.

                                <br/><br/>
                                Additionally I have trained as a Cognition Behavioural Psychotherapist which qualifies
                                me to offer evidenced-based
                                therapy for depression and a number of anxiety difficulties such as, social anxiety,
                                panic disorder, generalised anxiety and
                                obsessive compulsive disorder (OCD).
                                <br/>
                                <br/>
                                Cognitive Behavioural Therapy or CBT, is a family of talking therapies, all based on the
                                idea that thoughts, feelings, what we do,
                                and how our bodies feel, are all connected. If we change one of these we can alter all
                                the others.
                                When we’re low or upset, we often fall into patterns of thinking and responding which
                                can worsen how we feel.
                                CBT works to help us notice and change problematic thinking styles or behaviour patterns
                                so we can feel better.
                                CBT is a collaborative therapy - it’s not something that is done to someone, it’s a way
                                of working together
                                with a CBT therapist on mutually agreed goals.
                                <br/><br/>
                               The british association of counselling and psychotherapy (BACP) is the leading professional body
                                committed to developing the highest standards of proficiency and ethical practice within the counselling professions.
                                As a member of the BACP I adhere to their ethical framework for good practice in counselling and psychotherapy.
                                My BACP membership number is 384468.
                            </p>
                        </blockquote>
                    </div>
                    <div className="lg:col-end-1 sm:mx-auto w-72 lg:row-span-4 lg:w-72">
                        <img
                            className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                            src="https://i.imgur.com/FkK32Kr.jpeg"
                            alt=""
                        />
                    </div>


                </figure>
            </div>
        </section>
    )
}
