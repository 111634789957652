import React,{useState,useEffect,useRef} from 'react';
import { HiAcademicCap } from 'react-icons/hi';

const qualifications = [
    "PG Dip Cognitive Behavioural Therapy (CBT) (Commendation)",
    "Ba Hons 1st – Counselling (Incorporating Cognitive Behavioural Therapy)",
    "PgCert Enhanced Evidence - Based Practice for Children, Young People and Families (Distinction)",
    "FdA – Therapeutic Counselling (Incorporating Person-Centred Approach, Psychodynamic & Cognitive Behavioural Therapy) ",
    "Certified Rewind Therapist for PTSD (post-traumatic stress disorder)",
    "Level 3 - Counselling Skills",
    "Level 2 – Certificate in counselling",
    "Level 3 – Safeguarding Children and Vulnerable Adults",
    "Diploma - Mindfulness Based Stress Reduction Programme",
    "Certificate in Common Mental Health Problems",
    "Certificate in On-line and Telephone Counselling",
    "Extensive CPD - working with Trauma",
    "CPD – Transactional Analysis (TA)",
    "CPD – Polyvagal Theory",
    "Rewind Therapy for PTSD",
].map((content, index) => ({
    id: index + 1,
    content: content,
    icon: HiAcademicCap, // You can replace this with the icon you want to use
    iconBackground: 'bg-gray-400', // You can customize the background color of the icon
}));

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Qualifications()  {
    return (
        <section id="qualifications" className={'snap-start shrink-0 '}>
            <div className='h-full m-auto flex'>
                    <div className="flow-root w-1/2 m-auto">
                        <ul role="list" className="">
                            {qualifications.map((event, eventIdx) => (
                                <li key={event.id}>
                                    <div className="relative pb-8">
                                        {eventIdx !== Qualifications.length - 1 ? (
                                            <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                                  aria-hidden="true"/>
                                        ) : null}
                                        <div className="relative flex space-x-3">
                                            <div>
                        <span
                            className={classNames(
                                event.iconBackground,
                                'h-8 w-8 rounded-full flex items-center justify-center '
                            )}
                        >
                          <event.icon className="h-5 w-5 text-white" aria-hidden="true"/>
                        </span>
                                            </div>
                                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                <div>
                                                    <p className="text-sm text-gray-500">
                                                        {event.content}{' '}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
            </div>
        </section>
    );
};

