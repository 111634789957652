import {useState} from "react";

import Header from './components/Header'
import Welcome from "./components/Welcome";
import Qualifications from "./components/Qualifications";
import About from "./components/About";
import Pricing from "./components/Pricing";
import Contact from "./components/Contact";
import Location from "./components/Location";
import ReWindModal from "./components/RewindModal";



export default function App() {
    const [rewindOpen, setRewindOpen] = useState(false)

    return (
      <div>
          <div className="h-full flex-col gap-6 snap-y snap-mandatory overflow-y-auto">
              <ReWindModal open={rewindOpen} setOpen={setRewindOpen} />

              <Header openRewindModal={setRewindOpen} />
              <Welcome/>
              <About/>
              <Qualifications/>
              <Pricing/>
              <Location />
              <Contact/>
          </div>
      </div>

    )
}
