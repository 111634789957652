import React from 'react';
import { GoogleMap, LoadScript,Marker } from '@react-google-maps/api';

// Google Maps component configuration
const mapContainerStyle = {
    width: '100%',
    height: '100%',
    border: '5px solid black',
};

const position = {
    lat: 54.52346,
    lng: -1.55848,
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyApIE5q_QTsG-wo34QoPVfPOHpzDGDJijI';

export default function Location() {
    return (
        <section id="location" className="snap-start shrink-0 w-full h-full flex flex-col justify-center items-center">
            {/* Map container with black borders */}
            <div className="w-full h-1/2 md:h-1/2 lg:w-1/2 lg:h-3/4"
                 style={{border: '5px solid black', marginBottom: '20px'}}>
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={position}
                        zoom={10}
                    >
                        <Marker
                            position={position}
                            title="Amity House" // Title on hover
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
            {/* Location description below the map */}
            <div className="w-full lg:w-1/2 text-center p-4">
                <p>
                    Amity House is located at 5 Coniscliffe Rd, Darlington DL3 7EE
                </p>
            </div>
        </section>
    );
}