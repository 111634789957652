import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {AiOutlineBars,AiOutlineClose} from "react-icons/ai"

const navigation = [
    { name: 'About', href: '#about' },
    { name: 'Qualifications', href: '#qualifications' },
    // { name: 'Types of Therapy', href: '#therapy' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'Location', href: '#location' },
    { name: 'Contact', href: '#contact' },
    { name: 'Rewind Therapy', href:'_rewind', link:false}
]

export default function Header({openRewindModal}) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="z-50 fixed w-full top-0">
            <nav className="flex items-center justify-between p-6 lg:px-8 bg-gray-200 shadow-2xl" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#welcome" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img className="h-20 w-auto" src="https://i.imgur.com/pcJj7rH.png" alt="" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <AiOutlineBars className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        item.href==="_rewind" ? (
                            <span key={item.name} onClick={() => openRewindModal(true)}
                               className="hover:cursor-pointer text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 text-decoration-none">
                                {item.name}
                            </span>
                        ) : (
                            <a key={item.name} href={item.href}
                               className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 text-decoration-none">
                                {item.name}
                            </a>
                        )


                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">

                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-200 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#welcome" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-20 w-auto"
                                src="https://i.imgur.com/pcJj7rH.png"
                                alt=""
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <AiOutlineClose className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    item.href === "_rewind" ? (
                                        <span key={item.name} onClick={() => openRewindModal(true)}
                                           className="hover:cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-300  hover:text-blue-600 text-decoration-none"
                                        >
                                            {item.name}
                                        </span>
                                        ) : (
                                        <a key={item.name} href={item.href}
                                           className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-300  hover:text-blue-600 text-decoration-none"
                                        >
                                            {item.name}
                                        </a>
                                    )


                                ))}
                            </div>
                            <div className="py-6">

                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}
